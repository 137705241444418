/**Данная страница выводится при попытке перейти на не существующую страницу*/
import React from "react"
import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <div className="container not-found">
      <h1>Страница не найдена</h1>
      <p>Вы просто выбрали маршрут, которого не существует.</p>
    </div>
  </Layout>
)

export default NotFoundPage
